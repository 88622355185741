<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script lang="ts">
  export default {
     data() {
      return {
      }
    },   
    created(){
      if(this.getUrlKey('mac')){
        localStorage.setItem('mac',this.getUrlKey('mac'))
      }
    },
    methods: {
      getUrlKey(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
      },
    }
    
  }
</script>

<style>
  #app {
    min-height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* div,ul,li,p,h3{
    margin: 0;
    padding: 0;
  } */

  .flex1{
    flex: 1;
  }
  .inbk{
    display: inline-block;
  }
  .overs{
    overflow: hidden;
  }
  .vertic{
    vertical-align: middle;
  }
</style>
