import Vue from 'vue'
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    // component: () => import('../views/About.vue'),
    component: () => import('../views/SceneSelection.vue'),
    meta: {
      title: '设备激活'
    }
  },
  {
    path: '/Selection',
    name: 'Selection',
    component: () => import('../views/About.vue'),
    meta: {
      title: '激活设备'
    }
  },
  {
    path: '/drawSuccess',
    name: 'drawSuccess',
    component: () => import('../views/drawSuccess.vue'),
    meta: {
      title: '领取权益'
    }
  },
  {
    path:'/terminal',
    name: 'drawSuccess',
    component: () => import('../views/terminal.vue'),
    meta: {
      title: ''
    }
  }
]
console.log(process.env.BASE_URL);
const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title;
  }
  // if(!localStorage.getItem('token') && to.path!='/login'){
  //   router.push('/login');
  //   return false;
  // }
  next();
})

export default router
